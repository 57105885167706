import {
  Box,
  Center,
  FormLabel,
  HStack,
  Stack,
  Switch,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import {
  raffleCategoryFilterType,
  raffleMinType,
  raffleOrderByType,
  raffleStatusType,
} from '../../techRaffles/types'
import {
  useAllRaffles,
  useRafflesAndUserRafflesByProjectPublicId,
} from '../../techRaffles/hooks/raffle'
import { Element } from 'react-scroll'
import FilterButtons from './FilterButtons'
import RaffleCardList from './RaffleCardList'
import OrderSelect from './OrderSelect'
import InfiniteScroll from 'react-infinite-scroll-component'
import RaffleCardListSkeleton from './Skeletons/RaffleCardListSkeleton'
import { useLocation } from 'react-use'
import { LoadingMoreRaffles } from './LoadingMoreRaffles'
import { Select } from 'chakra-react-select'
import { useTokens, useTokensQuick } from '../../techRaffles/hooks/token'
import RaffleTableList from './RaffleTable/RaffleTableList'

const orderByLabels: { label: string; value: raffleOrderByType }[] = [
  { label: 'Ending Soon', value: 'ENDING_SOON' },
  {
    label: 'Recently added',
    value: 'RECENTLY_ADDED',
  },
  { label: 'floor price (24h) desc.', value: 'FLOOR' },
  { label: 'avg. price (24h) desc.', value: 'AVG24' },
]

const filterLabels: { label: string; value: raffleCategoryFilterType }[] = [
  { label: 'NFTs', value: 'NFT' },
  {
    label: 'WL',
    value: 'WL',
  },
  {
    label: 'IRL',
    value: 'IRL',
  },
  { label: 'ALL', value: 'ALL' },
]

const statusLabels: { label: string; value: raffleStatusType }[] = [
  { label: 'Featured', value: 'FEATURED' },
  {
    label: 'ALL',
    value: 'ALL',
  },
  { label: 'Ended', value: 'ENDED' },
]

export default function LandingPageRaffles() {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  const [status, setStatus] = useState<raffleStatusType>('FEATURED')
  const [view, setView] = useState<'CARD' | 'TABLE'>('CARD')
  const [orderBy, setOrderBy] = useState<raffleOrderByType>('ENDING_SOON')
  const [raffleTypeFilterBy, setRaffleTypeFilterBy] =
    useState<raffleCategoryFilterType>('ALL')
  const [tokenAcceptedFilter, setTokenAcceptedFilter] = useState<string[]>([])
  const [page, setPage] = useState<number>(0)
  const [raffles, setRaffles] = useState<raffleMinType[]>([])
  const [hasMore, setHasMore] = useState(true)

  const setFilterBy = (it: raffleCategoryFilterType) => {
    setRaffleTypeFilterBy(it)
    history.replaceState(null, '', '#' + it)
  }

  const { data: tokens, isLoading: tokensLoading } = useTokensQuick()

  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      const queryCategoryParam = location.hash?.split('#')[1]
      console.log('queryCategoryParam', queryCategoryParam)
      if (queryCategoryParam && raffleTypeFilterBy != queryCategoryParam) {
        setRaffleTypeFilterBy(queryCategoryParam as raffleCategoryFilterType)
      }
    }, 10)
  }, [location])

  const { data: rafflesData, isFetching } = useAllRaffles({
    orderBy: orderBy,
    filter: raffleTypeFilterBy,
    status: status,
    page: page,
  })

  useEffect(() => {
    if (rafflesData) {
      const filtered = rafflesData.filter((r) => {
        for (const raffle of raffles) {
          if (r.id === raffle.id) {
            return false
          }
        }
        return true
      })

      const newArr = [...raffles, ...filtered]
      if (hasMore && newArr.length === raffles.length) {
        setHasMore(false)
      }

      setRaffles(newArr)
    }
  }, [rafflesData])

  useEffect(() => {
    setRaffles([])
    setPage(0)
    setHasMore(true)
  }, [orderBy, raffleTypeFilterBy, status])

  const rafflesShown = useMemo(() => {
    console.log({ tokenAcceptedFilter })

    if (tokenAcceptedFilter.length === 0) {
      return raffles
    }

    return raffles.filter((r) => {
      return r.allowedPurchaseTokens.some((t) =>
        tokenAcceptedFilter.includes(t.token.symbol)
      )
    })
  }, [tokenAcceptedFilter, raffles])

  return (
    <Element name='Public Raffles'>
      <Box id='public-raffles'>
        <Text fontSize='1.875rem' fontWeight='600'>
          Public Raffles
        </Text>
        <Stack
          mt={3}
          direction={['column', 'column', 'row', 'row']}
          justify='space-between'
          gap={2}
        >
          <Stack
            justify={'space-between'}
            direction={['column', 'column', 'row', 'row']}
            gap={[4, 0]}
          >
            <FilterButtons
              selectedValue={status}
              onChange={(it) => setStatus(it as raffleStatusType)}
              labels={statusLabels}
            ></FilterButtons>
            <Stack
              direction={['column', 'column', 'row', 'row']}
              justify='space-between'
              paddingLeft={[0, 0, 4]}
            >
              <Text fontSize='1rem'>View</Text>
              <FilterButtons
                selectedValue={view}
                onChange={(it) => setView(it as 'CARD' | 'TABLE')}
                labels={[
                  { value: 'CARD', label: 'Cards' },
                  { value: 'TABLE', label: 'Table' },
                ]}
              ></FilterButtons>
            </Stack>
          </Stack>

          <Stack
            justify={'space-between'}
            direction={['column', 'column', 'row', 'row']}
            gap={[4, 0]}
          >
            <Stack
              direction={['column', 'column', 'row', 'row']}
              justify='space-between'
            >
              <Text fontSize='1rem'>Type</Text>
              <Select
                onChange={(value: any) => {
                  console.log(value)
                  if (value) {
                    setFilterBy(value.value)
                  }
                }}
                chakraStyles={{
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    px: 2,
                    cursor: 'inherit',
                    rounded: 'full',
                    border: 'none',
                    bg: 'transparent',
                  }),
                  indicatorSeparator: (provided) => ({
                    ...provided,
                    display: 'none',
                  }),
                  container: (provided) => ({
                    ...provided,
                    minWidth: '100px',
                    maxWidth: '180px',
                    borderRadius: 'full',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: isDarkMode ? '#ddd' : '#888',
                  }),
                  control: (provided) => ({
                    ...provided,
                    color: isDarkMode ? '#fff' : 'black',
                    border: isDarkMode
                      ? '1px solid #888 !important'
                      : '1px solid #ccc',
                    borderRadius: 'full',
                    rounded: 'full',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: isDarkMode ? 'cardBlack' : 'cardWhite',
                    border: isDarkMode ? '1px solid #fff' : '1px solid #ccc',
                    color: isDarkMode ? 'white' : 'black',
                    borderRadius: '14px',
                    zIndex: 77,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    backgroundColor: isDarkMode ? 'cardBlack' : 'cardWhite',
                    zIndex: 77,
                  }),
                }}
                value={{
                  label: raffleTypeFilterBy.toString(),
                  value: raffleTypeFilterBy,
                }}
                options={filterLabels}
              ></Select>
            </Stack>

            <Stack
              direction={['column', 'column', 'row', 'row']}
              justify='space-between'
              paddingLeft={[0, 0, 4]}
            >
              <Text fontSize='1rem'>Accepted</Text>
              <Select
                value={tokenAcceptedFilter.map((tSymbol) => ({
                  label: tSymbol,
                  value: tSymbol,
                }))}
                onChange={(value: any) => {
                  console.log(value)
                  setTokenAcceptedFilter(value.map((v: any) => v.value))
                }}
                chakraStyles={{
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    px: 2,
                    cursor: 'inherit',
                    rounded: 'full',
                    border: 'none',
                    bg: 'transparent',
                  }),
                  indicatorSeparator: (provided) => ({
                    ...provided,
                    display: 'none',
                  }),
                  container: (provided) => ({
                    ...provided,
                    minWidth: '180px',
                    maxWidth: '300px',
                    borderRadius: 'full',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: isDarkMode ? '#ddd' : '#888',
                  }),
                  control: (provided) => ({
                    ...provided,
                    color: isDarkMode ? '#fff' : 'black',
                    border: isDarkMode
                      ? '1px solid #888 !important'
                      : '1px solid #ccc',
                    borderRadius: 'full',
                    rounded: 'full',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: isDarkMode ? 'cardBlack' : 'cardWhite',
                    border: isDarkMode ? '1px solid #fff' : '1px solid #ccc',
                    color: isDarkMode ? 'white' : 'black',
                    borderRadius: '14px',
                    zIndex: 77,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    backgroundColor: isDarkMode ? 'cardBlack' : 'cardWhite',
                    zIndex: 77,
                  }),
                }}
                isMulti
                placeholder='Choose tokens'
                options={[
                  {
                    label: 'DEX-listed',
                    options: (tokens ?? [])
                      .filter((t) => t.onDEX)
                      .map((it) => ({ label: it.symbol, value: it.symbol })),
                  },
                  {
                    label: 'Non-DEX-listed',
                    options: (tokens ?? [])
                      .filter((t) => !t.onDEX)
                      .map((it) => ({ label: it.symbol, value: it.symbol })),
                  },
                ]}
              ></Select>
            </Stack>

            <Stack
              direction={['column', 'column', 'row', 'row']}
              justify='space-between'
              paddingLeft={[0, 0, 4]}
            >
              <Text fontSize='1rem'>Sort</Text>
              <OrderSelect
                values={orderByLabels}
                onChange={(it) => setOrderBy(it as raffleOrderByType)}
                selectedValue={orderBy}
                disabled={status === 'ENDED'}
              />
            </Stack>
          </Stack>
        </Stack>

        {raffles && raffles.length > 0 ? (
          <>
            {view === 'CARD' ? (
              <InfiniteScroll
                scrollThreshold={0.75}
                dataLength={raffles.length}
                next={() => setPage(page + 1)}
                hasMore={hasMore}
                loader={
                  <Center mt='3rem'>
                    <LoadingMoreRaffles />
                  </Center>
                }
                endMessage={
                  <Center mt='3rem'>
                    {!isFetching ? <Text></Text> : <LoadingMoreRaffles />}
                  </Center>
                }
              >
                <RaffleCardList raffles={rafflesShown} />
              </InfiniteScroll>
            ) : (
              <Box w='100%' mt={['1.5rem', '2rem', '3rem']}>
                <RaffleTableList showMinimal={false} raffles={rafflesShown} hideBuy={status === 'ENDED'} />
              </Box>
            )}
          </>
        ) : isFetching && (raffles?.length ?? 0) < 1 ? (
          <RaffleCardListSkeleton />
        ) : (
          <Center mt='3rem'>
            <Text>No raffles available</Text>
          </Center>
        )}
      </Box>
    </Element>
  )
}
